


























































































import Vue from 'vue';
import store from '@/store';
import { toastSuccess, toastError } from '@/concerns/helpers';

export default Vue.extend({
  name: 'SignIn',

  data() {
    return {
      submittingSignIn: false,
      submittingSignUp: false,
      username: "",
      password: "",
    };
  },

  computed: {
    submitting(): boolean {
      return this.submittingSignIn || this.submittingSignUp;
    },
  },

  methods: {
    toastSuccess,
    toastError,

    signIn(): void {
      const { username, password } = this;
      this.submittingSignIn = true;
      store.dispatch('signIn', {
        username,
        password,
      }).then(() => {
        const { nextUrl } = this.$route.params;
        this.$router.push(nextUrl || { name: 'ThreadIndex' });
        this.toastSuccess("Signed in!");
      }).catch((reason) => {
        this.toastError(reason);
      }).finally(() => {
        this.submittingSignIn = false;
      });
    },

    signUp(): void {
      const { username, password } = this;
      this.submittingSignUp = true;
      store.dispatch('signUp', {
        username,
        password,
      }).then(() => {
        const { nextUrl } = this.$route.params;
        this.$router.push(nextUrl || { name: 'ThreadIndex' });
        this.toastSuccess("Signed up and signed in!");
      }).catch((reason) => {
        this.toastError(reason);
      }).finally(() => {
        this.submittingSignUp = false;
      });
    },
  },
});
